import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setProfileDrawerVisible } from "../../../../../../../actions/shared/uiActions";

function MoreReportsLink({ closeProfileDrawer, history }) {
  const handleClick = () => {
    closeProfileDrawer();
    history.push("/employer/reports/legacy_agencies");
  };

  return (
    <div className="small" style={{ marginTop: 24 }}>
      Looking for more reports?
      {" "}
      <span onClick={handleClick} className="pseudo-link link">
        Click here
      </span>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  closeProfileDrawer: () => dispatch(setProfileDrawerVisible(false)),
});

export default withRouter(connect(null, mapDispatchToProps)(MoreReportsLink));
