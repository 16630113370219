import React from "react";

import { useDispatch } from "react-redux";

import { openModal } from "../../../../actions/shared/uiActions";
import Button from "../../../forms/custom/Button";

import PreAssignmentModal from "./PreAssignmentModal";

function OpenPreAssignmentModalCTA({
  candidate,
  overrideStyle = {},
  overloadedClasses,
}) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openModal(<PreAssignmentModal {...{ candidate }} />));
  };

  return (
    <Button
      color="blue"
      variant="primary"
      onClick={handleClick}
      style={{ minWidth: 165, ...overrideStyle }}
      overloadedClasses={overloadedClasses}
      data-cy="open-preassignment-modal-button"
      disabled={candidate.attributes.status !== "offer"}
    >
      Complete Steps
    </Button>
  );
}

export default OpenPreAssignmentModalCTA;
