import normalize from "json-api-normalizer";
import queryString from "query-string";
import { batch } from "react-redux";

import { GET, POST, PATCH } from "../../util/apiHelpers";
import { mapKeysToCamelCase, mapKeysToSnakeCase } from "../../util/formatHelpers";
import {
  receiveCandidate,
  receiveCandidates,
} from "../shared/candidateActions";
import {
  fetchContractSuccess,
  receiveContracts,
  fetchContractsError,
} from "../shared/contractActions";
import { receiveJobcasts, receiveJobCast } from "../shared/jobcastActions";
import {
  fetchPayPeriodSuccess,
  receivePayPeriods,
} from "../shared/payPeriodActions";
import { refetchLeftDrawerCounts } from "../shared/uiActions";

export function fetchContracts(params = {}) {
  const baseUrl = "/api/v3/recruiter/contracts";

  const queryStr = queryString.stringify(mapKeysToSnakeCase(params));

  const url = queryStr ? `${baseUrl}?${queryStr}` : baseUrl;

  return (dispatch) => {
    return GET(url)
      .then((response) => {
        const normalized = normalize(response.data);

        batch(() => {
          dispatch(receiveContracts(normalized.contract));
          dispatch(receiveCandidates(normalized.candidate));
          dispatch(receiveJobcasts(normalized.jobcast));
          dispatch(receivePayPeriods(normalized.payPeriod));
        });

        return { items: normalized.contract, metaData: mapKeysToCamelCase(response.data.meta) };
      })
      .catch((error) => {
        dispatch(fetchContractsError(error));
      });
  };
}

export const createContract = (data) => (dispatch) =>
  POST("/api/v3/employer/contracts", data)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveCandidates(normalized.candidate));
      });
    })
    .catch((errors) => Promise.reject(errors.response.data));

export const fetchContract = (candidateId) => (dispatch) =>
  GET(`/api/v3/recruiter/candidates/${candidateId}/contract`).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(fetchContractSuccess(normalized.contract));
      dispatch(receiveCandidates(normalized.candidate));
      dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
    });
  });

export const completePreassignment = (data) => (dispatch) => {
  return PATCH(
    `/api/v3/recruiter/contracts/${data.contract_id}/complete_preassignment`,
    data
  ).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(fetchContractSuccess(normalized.contract));
      dispatch(receiveCandidates(normalized.candidate));
      dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
    });
  });
};

export const closeContract = (candidateId, data) => (dispatch) =>
  PATCH(`/api/v3/recruiter/candidates/${candidateId}/contract/close`, data)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveCandidate(normalized.candidate[candidateId]));
        dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
        dispatch(receiveJobCast(normalized.jobcast));
        dispatch(refetchLeftDrawerCounts());
      });
    })
    .catch((errors) => Promise.reject(errors));

export const editContract = (candidateId, _contractId, data) => (dispatch) =>
  PATCH(`/api/v3/recruiter/candidates/${candidateId}/contract`, data)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveCandidate(normalized.candidate[candidateId]));
      });
    })
    .catch((errors) => Promise.reject(errors));
