import Star from "@material-ui/icons/Star";
import includes from "lodash/includes";
import React, { Component } from "react";
import { connect } from "react-redux";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { rejectCandidate } from "../../../../../../../actions/employers/candidateActions";
import {
  clearModal,
  setProfileDrawerVisible,
} from "../../../../../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../../../../../selectors/individualSelectors";
import {
  inputValue,
  errorString,
  hasErrors,
  updateFormValue,
  validateFields,
} from "../../../../../../../util/formHelpers";
import MultiLineTextField from "../../../../../../forms/custom/MultiLineTextField";
import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";

import withSnackbar from "../../../../../../General/withSnackbar";

import RateRecruiterModalContent from "./RateRecruiter/RateRecruiterModalContent";

const RATING_SOLICITATION_STAGES = ["interviewing", "offer"];

// brychael-lezul: remove form helpers, switch to formik
class RejectModal extends Component {
  constructor(props) {
    super(props);

    this.reasonInputId = "reject_candidate_reason";

    this.state = {
      [this.reasonInputId]: inputValue(),
      submitted: false,
      displayPostRejectionRating: false,
      loading: false,
      notEligibleForOwnership: false,
    };

    this.inputErrorString = errorString.bind(this);
    this.inputHasErrors = hasErrors.bind(this);
    this.updateInputValue = updateFormValue.bind(this);
    this.validateFields = validateFields.bind(this);
    this.preSubmitValidations = {
      [this.reasonInputId]: { required: true, minWords: 3 },
    };
    this.renderFeedback = this.renderFeedback.bind(this);
  }

  handleCheckboxChange = (event) => {
    this.setState({ notEligibleForOwnership: event.target.checked });
  };

  handleSubmit = () => {
    if (this.state.submitted) {
    } else {
      const { candidate } = this.props;
      const prevStatus = candidate.attributes.status;

      this.validateFields(this.preSubmitValidations)
        .then(() => {
          this.setState({ submitted: true, loading: true });
          this.props
            .rejectCandidate({
              candidateId: candidate.id,
              reason: this.state[this.reasonInputId].value,
              notEligibleForOwnership: this.state.notEligibleForOwnership,
            })
            .then(
              this.props.setProfileDrawerNotVisible,
              this.props.snackbar.showMessage(
                "The candidate has been rejected."
              )
            )
            .then(() => {
              if (includes(RATING_SOLICITATION_STAGES, prevStatus)) {
                this.setState({ displayPostRejectionRating: true });
              } else {
                this.props.clearModal();
              }
            });
        })
        .catch(this.setState({ loading: false }));
    }
  };

  renderContent() {
    return (
      <>
        <p style={{ margin: "0 0 24px 0" }}>
          Provide the submitting agency with the reason their candidate is being
          rejected. The agency will be notified and will use your feedback to
          improve submissions going forward.
        </p>
        <MultiLineTextField
          error={this.inputHasErrors(this.reasonInputId)}
          helperText={this.inputErrorString(this.reasonInputId)}
          id={this.reasonInputId}
          label="Tell the agency what could be improved"
          onChange={this.updateInputValue(this.reasonInputId, {
            minWords: 3,
            bounce: true,
          })}
          value={this.state.reject_candidate_reason.value}
          rows={3}
          placeholder="This candidate would be a better fit if they had a few more years managing a team."
          style={{ marginBottom: 16 }}
        />
        <FormControlLabel
          style={{ margin: "0 0 0 -9.5px" }}
          control={
            <Checkbox
              checked={this.state.notEligibleForOwnership}
              onChange={this.handleCheckboxChange}
              color="primary"
              data-cy="not-eligible-for-ownership-checkbox"
            />
          }
          label={
            <span>
              Reject agency{' '}
              <a 
                href="https://help.recruitifi.com/en/articles/10475818-how-do-i-reject-agency-ownership-of-a-candidate"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#008DAE', textDecoration: 'underline' }}
              >
                ownership eligibility
              </a>
              {' '}
            </span>
          }
        />
      </>
    );
  }

  renderFeedback() {
    const { feedbackRating } = this.props;

    if (!feedbackRating) {
      return <></>;
    }
    return (
      <div
        className="subtitle-2"
        style={{ display: "flex", alignItems: "center" }}
      >
        Your Feedback Helpfulness Rating
        <a
          href="/app/employer/reports/my_ratings"
          style={{
            display: "flex",
            marginLeft: 8,
            textDecoration: "underline",
          }}
        >
          <Star />
          {this.props.feedbackRating}
        </a>
      </div>
    );
  }

  render() {
    const { candidate } = this.props;
    const { loading, displayPostRejectionRating } = this.state;

    return (
      <ActionModalContainer color="red">
        <ActionModalHeader
          title="Reject Candidate"
          subtitle={`${candidate.attributes.firstName} ${candidate.attributes.lastName}`}
        />
        {displayPostRejectionRating ? (
          <RateRecruiterModalContent candidateId={candidate.id} />
        ) : (
          <>
            <ActionModalContent>{this.renderContent()}</ActionModalContent>
            <ActionModalFooter
              actionText="Reject"
              handleAction={this.handleSubmit}
              isSubmitting={loading}
            >
              {this.renderFeedback()}
            </ActionModalFooter>
          </>
        )}
      </ActionModalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const currentIndividual = getCurrentIndividual(state);
  return {
    feedbackRating: currentIndividual.aggregateRatings.feedbackRatings
      .numReviews
      ? currentIndividual.aggregateRatings.feedbackRatings.average.toFixed(2)
      : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProfileDrawerNotVisible: () => dispatch(setProfileDrawerVisible(false)),
  rejectCandidate: (data) => dispatch(rejectCandidate(data)),
  clearModal: () => dispatch(clearModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar()(RejectModal));
