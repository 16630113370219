import every from "lodash/every";
import filter from "lodash/filter";
import { createSelector } from "reselect";

import { getAllCandidates } from "./candidateSelectors";
import { makeGetContractItems } from "./contractSelector";
import createDeepEqualSelector from "./createDeepEqualSelector";
import { getAllJobcasts } from "./jobcastSelectors";

function buildFilterFunctions({
  ids,
  contractId,
  contractIds,
  submitted,
  approved,
  disputed,
  collected,
  paid,
  collectable,
  payable,
  hideZeroHours,
  costCenterInvoicePeriodId,
  submittedHistory,
  employerSubmittable,
  agencySubmittable,
  agencyActionComplete
}) {
  const filterFunctions = [];

  if (ids) {
    const idSet = new Set(ids);

    filterFunctions.push(({ attributes }) => idSet.has(attributes.id));
  }

  if (contractId) {
    filterFunctions.push(
      ({ attributes }) => attributes.contractId === contractId
    );
  }

  if (contractIds && contractIds.length) {
    const contractIdSet = new Set(contractIds);

    filterFunctions.push(
      ({ attributes }) => contractIdSet.has(attributes.contractId)
    );
  }

  if (typeof submitted === "boolean") {
    filterFunctions.push(
      ({ attributes }) => Boolean(attributes.submittedAt) === Boolean(submitted)
    );
  }

  if (typeof submittedHistory === "boolean") {
    filterFunctions.push(({ attributes }) =>
      Boolean(attributes.submittedAt) === Boolean(submittedHistory) || attributes.disputedAt);
  }

  if (typeof approved === "boolean") {
    filterFunctions.push(
      ({ attributes }) => Boolean(attributes.approvedAt) === Boolean(approved)
    );
  }

  if (typeof disputed === "boolean") {
    filterFunctions.push(
      ({ attributes }) => Boolean(attributes.disputedAt) === Boolean(disputed)
    );
  }

  if (typeof collected === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        Boolean(attributes.collectionAt) === Boolean(collected)
    );
  }

  if (typeof paid === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        Boolean(attributes.stripeTransferCompletedAt) === Boolean(paid)
    );
  }

  if (typeof collectable === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        (parseFloat(attributes.adjustedEmployerCollectionAmount) > 0) === collectable
    );
  }

  if (typeof payable === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        (parseFloat(attributes.adjustedRecruiterPayoutAmount) > 0) === payable
    );
  }

  if (typeof hideZeroHours === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        (parseFloat(attributes.totalBilledHours) === 0) !== hideZeroHours ||
        (parseFloat(attributes.additionalBilling ?? "0") === 0) !== hideZeroHours
    );
  }

  if (costCenterInvoicePeriodId) {
    filterFunctions.push(
      ({ attributes }) =>
        attributes.costCenterInvoicePeriodId === costCenterInvoicePeriodId
    );
  }

  if (typeof employerSubmittable === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        Boolean(attributes.employerSubmittable) === Boolean(employerSubmittable)
    );
  }

  if (typeof agencySubmittable === "boolean") {
    filterFunctions.push(
      ({ attributes }) =>
        Boolean(attributes.agencySubmittable) === Boolean(agencySubmittable)
    );
  }

  if (typeof agencyActionComplete === "boolean") {
    filterFunctions.push(
      ({ attributes }) => {
        const isComplete = (attributes.agencySubmittable && attributes.submittedAt) ||
          (attributes.employerSubmittable && attributes.approvedAt);

        return Boolean(isComplete) === Boolean(agencyActionComplete);
      }
    );
  }

  return filterFunctions;
}

export function getPayPeriods({ payPeriods }) {
  return payPeriods;
}

export function makeGetPayPeriodItems() {
  return createSelector([getPayPeriods], ({ items }) => items);
}

export const makeGetFilteredPayPeriods = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  return createSelector(
    [makeGetPayPeriodItems(), filtersSelector],
    (items, filters) => {
      const filterFunctions = [].concat(buildFilterFunctions(filters));

      return filter(items, (item) =>
        every(filterFunctions, (filterFunction) => filterFunction(item)));
    }
  );
};

export const makeGetFilteredPayPeriodItems = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  return createSelector(
    [makeGetPayPeriodItems(), makeGetContractItems(), getAllCandidates, getAllJobcasts, filtersSelector],
    (payPeriodItems, contractItems, candidateItems, jobcasts, filters) => {
      const filterFunctions = [].concat(buildFilterFunctions(filters));

      const filteredPayPeriods = filter(payPeriodItems, (item) =>
        every(filterFunctions, (filterFunction) => filterFunction(item)));

      return filteredPayPeriods.map((payPeriod) => {
        const contract = contractItems[payPeriod.attributes.contractId];
        if (!contract) return null;

        const candidate = candidateItems[contract.attributes.recruiterSubmissionId];
        const jobcast = jobcasts[candidate.attributes.jobcastId];

        return {
          ...payPeriod,
          contract,
          recruiterSubmission: candidate,
          position: jobcast,
        };
      });
    }
  );
};
