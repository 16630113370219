import Tooltip from "@material-ui/core/Tooltip";
import React, { Component } from "react";
import { connect } from "react-redux";

import { openProfileDrawer } from "../../../../../../actions/shared/uiActions";

class Header extends Component {
  determineOnClick() {
    const {
      noLink,
      openProfileDrawer,
      drawerComponent,
      jobcastId
    } = this.props;

    return noLink
      ? () => {}
      : () => {
        openProfileDrawer(drawerComponent, jobcastId);
      };
  }

  renderTitle() {
    const { title, noLink } = this.props;
    const titleClass = noLink ? "truncate" : "truncate pseudo-link";
    return title.length > 20 && !noLink ? (
      <Tooltip title={title}>
        <h4
          className={titleClass}
          onClick={this.determineOnClick()}
        >
          {title}
        </h4>
      </Tooltip>
    ) : (
      <h4
        data-cy="candidate-card-title-link"
        className={titleClass}
        onClick={this.determineOnClick()}
      >
        {title}
      </h4>
    );
  }

  render() {
    const { avatar, subtitle } = this.props;
    return (
      <div
        style={{
          height: "80px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "336px"
        }}
      >
        {avatar &&
          React.cloneElement(avatar, {
            overrideStyle: { marginLeft: "12px", marginRight: "12px" }
          })}
        <div style={{ alignItems: "center" }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              maxWidth: "280px"
            }}
          >
            {this.renderTitle()}
            <span className="subtitle-1" data-cy="candidate-card-subtitle">{subtitle}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openProfileDrawer: (content, positionId) => dispatch(openProfileDrawer(content, positionId))
});

export default connect(
  null,
  mapDispatchToProps
)(Header);
