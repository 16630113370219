import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchJobCast as fetchEmployerJobCast } from "../../../actions/employers/jobcastActions";
import { fetchJobcastRequest as fetchRecruiterJobCast } from "../../../actions/recruiters/jobcastActions";
import {
  clearModal,
  openProfileDrawer,
} from "../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../selectors/individualSelectors";
import AgencyRecruiterDrawer from "../../employer/Agencies/view/AgencyRecruiterDrawer";
import EmployerCandidateDrawer from "../../employer/JobCasts/view/Candidates/view/CandidateDrawer";
import RecruiterCandidateDrawer from "../../recruiter/Candidates/view/CandidateDrawer";
import { JOBCASTS, CANDIDATES, AGENCIES } from "../Search/constants";
import { parseCandidateStatus, parseRecruiterCandidateStatusSection } from "../Search/utils";

import SearchResultItemBody from "./SearchResultItem/SearchResultItemBody";
import SearchResultsItemProfile from "./SearchResultItem/SearchResultItemProfile";
import SearchResultItemSupportInfo from "./SearchResultItem/SearchResultItemSupportInfo";

const styles = () => ({
  card: {
    borderRadius: 0,
    height: "60px",
    minHeight: "60px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(0, 0, 0, 0.08)",
    },
  },
  paper: {
    boxShadow: "none",
  },
  button: {
    border: "none",
    margin: "0",
    marginBottom: "10px",
    padding: "0",
    width: "auto",
    overflow: "visible",
    color: "inherit",
    font: "inherit",
    lineHeight: "normal",
    "-webkit-font-smoothing": "inherit",
    "-moz-osx-font-smoothing": "inherit",
    "-webkit-appearance": "none",
    width: "100%",
    outlineColor: "#008DAE",
    "&::-moz-focus-inner": {
      border: 0,
      padding: 0,
    },
  },
});

function SearchResultItem({
  classes,
  item,
  history,
  openProfileDrawer,
  fetchRecruiterJobCast,
  fetchEmployerJobCast,
  clearModal,
}) {
  const currentIndividual = useSelector(getCurrentIndividual);
  const handleClick = () => {
    if (currentIndividual.recruiter) {
      switch (item.type) {
        case JOBCASTS:
          fetchRecruiterJobCast(item.requestId).then(() => {
            history.push(`/my-jobcasts/${item.requestId}/description`);
          });

          clearModal();
          break;
        case CANDIDATES:
          const requestId = JSON.parse(item.description).request_id;

          fetchRecruiterJobCast(requestId).then(() => {
            history.push(
              `/my-jobcasts/${requestId}/${parseRecruiterCandidateStatusSection(
                item.status
              )}`
            );
            openProfileDrawer(
              <RecruiterCandidateDrawer
                candidateId={item.searchableId}
                navSelection="view_resume"
              />
            );
          });
          break;
        default:
          return null;
      }
    } else {
      switch (item.type) {
        case JOBCASTS:
          fetchEmployerJobCast(item.searchableId).then(() => {
            history.push(`/employer/perm/jobcasts/${item.searchableId}/description`);
          });

          clearModal();
          break;
        case CANDIDATES:
          const jobcastId = JSON.parse(item.description).position_id;

          fetchEmployerJobCast(jobcastId).then(() => {
            history.push(
              `/employer/perm/jobcasts/${jobcastId}/${parseCandidateStatus(item.status)}`
            );
            openProfileDrawer(
              <EmployerCandidateDrawer
                candidateId={item.searchableId}
                navSelection="view_resume"
              />
            );
          });
          break;
        case AGENCIES:
          openProfileDrawer(
            <AgencyRecruiterDrawer recruiterId={item.searchableId} />
          );
          break;
        default:
          return null;
      }
    }
  };

  return (
    <button type="button" tabIndex={0} onClick={handleClick} className={classes.button}>
      <Card
        className={classes.card}
        classes={{ root: classes.paper }}
        data-cy={`search-result-item-${item.type}`}
      >
        <SearchResultsItemProfile item={item} />
        <SearchResultItemBody item={item} />
        <SearchResultItemSupportInfo item={item} />
      </Card>
    </button>
  );
}

const mapDispatchToProps = (dispatch) => ({
  openProfileDrawer: (content, positionId) => dispatch(openProfileDrawer(content, positionId)),
  fetchRecruiterJobCast: (id) => dispatch(fetchRecruiterJobCast(id)),
  fetchEmployerJobCast: (id) => dispatch(fetchEmployerJobCast(id)),
  clearModal: () => dispatch(clearModal()),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(SearchResultItem)));
