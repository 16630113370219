import Star from "@material-ui/icons/Star";
import round from "lodash/round";
import React from "react";

import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../selectors/individualSelectors";

function StarRatingLink({ rating }) {
  const currentIndividual = useSelector(getCurrentIndividual);

  const url = currentIndividual.employer
    ? "/app/employer/reports/my_ratings"
    : "/app/settings/my_ratings";

  return rating ? (
    <div
      className="subtitle-2"
      style={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        paddingBottom: 8,
      }}
    >
      <Star style={{ color: "#f8c11c" }} />
      <a
        className="pseudo-link"
        style={{ color: "#008DAE", textDecoration: "underline" }}
        href={url}
        data-cy="star-rating-link"
      >
        {round(rating, 1)}
      </a>
    </div>
  ) : (
    <></>
  );
}

export default StarRatingLink;
