import { makeStyles, MenuItem, Select } from "@material-ui/core";
import find from "lodash/find";
import ChevronDown from "mdi-material-ui/ChevronDown";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { setHeaderMenuScope } from "../../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { usePrevious } from "../../../General/customHooks/usePrevious";

import {
  EMPLOYER_TAB_OPTIONS,
  EMPLOYER_SCOPE_OPTIONS,
  extractKeyFromPath,
  PERM_SCOPE,
  RECRUITER_TAB_OPTIONS,
  TEMP_SCOPE,
} from "./constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRight: "1px solid #E0E0E0",
    paddingRight: theme.spacing(2),
  },
  selectRoot: {
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      "& svg": {
        color: "#008dae",
      },
      "& select": {
        color: "#008dae",
      },
    },
  },
  select: {
    color: "#008DAE",
    width: "100%",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.75,
    textTransform: "uppercase",
    "&:focus": {
      backgroundColor: "white",
    },
  },
  selectIcon: {
    fontSize: 24,
    top: "calc(50% - 13px)",
    color: "#008DAE",
  },
}));

const SELECTED_VALUE_LABELS = {
  [PERM_SCOPE]: "Perm",
  [TEMP_SCOPE]: "Contract",
};

function TabsScopeToggle({ history }) {
  const dispatch = useDispatch();

  const scope = useSelector((state) => state.ui.headerMenu.scope);
  const currentIndividual = useSelector(getCurrentIndividual);

  const handleChange = (event) => {
    const newScope = event.target.value;

    dispatch(setHeaderMenuScope(newScope));

    // update url path to first option of newly selected scope
    const defaultTab = find(
      currentIndividual.employer ? EMPLOYER_TAB_OPTIONS : RECRUITER_TAB_OPTIONS,
      { exclusiveScope: newScope, isScopedDefault: true }
    );

    if (defaultTab.handleClick) {
      defaultTab.handleClick(history);
    } else {
      history.push({ pathname: `/${defaultTab.key}` });
    }
  };

  const currTabKey = extractKeyFromPath(history.location.pathname);
  const prevTabKey = usePrevious(currTabKey);

  // if the url changes to a tab that is exclusive to a different scope, change the scope
  useEffect(() => {
    if (currTabKey === prevTabKey) return () => {};

    if (currentIndividual.employer) {
      const scopeOptions = EMPLOYER_SCOPE_OPTIONS;
      if (scopeOptions[currTabKey] && scopeOptions[currTabKey] !== scope) {
        dispatch(setHeaderMenuScope(scopeOptions[currTabKey]));
      }
    } else {
      const tabOptions = RECRUITER_TAB_OPTIONS;

      if (
        tabOptions[currTabKey]?.exclusiveScope &&
        tabOptions[currTabKey].exclusiveScope !== scope
      ) {
        dispatch(setHeaderMenuScope(tabOptions[currTabKey].exclusiveScope));
      }
    }

    return () => {};
  }, [currTabKey, prevTabKey, currentIndividual.employer, scope, dispatch]);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Select
        data-cy="temp-dropdown"
        value={scope}
        onChange={handleChange}
        classes={{
          root: classes.selectRoot,
          select: classes.select,
          icon: classes.selectIcon,
        }}
        disableUnderline
        IconComponent={ChevronDown}
        renderValue={(value) => SELECTED_VALUE_LABELS[value]}
      >
        <MenuItem value={PERM_SCOPE}>Perm Placement</MenuItem>
        <MenuItem value={TEMP_SCOPE}>Contract Roles</MenuItem>
      </Select>
    </div>
  );
}

export default withRouter(TabsScopeToggle);
