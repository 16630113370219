import normalize from "json-api-normalizer";
import { batch } from "react-redux";

import { GET, PATCH } from "../../util/apiHelpers";

import { receivePreferredLinks } from "../preferredLinkActions";
import { receiveIndividuals } from "../shared/individualActions";
import { receiveJobcasts } from "../shared/jobcastActions";
import { receiveOrganizations } from "../shared/organizationActions";
import { receiveRequests } from "../shared/requestActions";

export const fetchRequests = (jobCastId) => (dispatch) => GET(`/api/v3/employer/jobcasts/${jobCastId}/requests`).then((res) => {
  const { request } = normalize(JSON.parse(res.data.requests));
  const { recruiter, recruiterOrganization } = normalize(
    JSON.parse(res.data.individuals)
  );
  const { preferredLink } = normalize(JSON.parse(res.data.preferred_links));

  return batch(() => {
    recruiter && dispatch(receiveIndividuals(recruiter));
    recruiterOrganization &&
        dispatch(receiveOrganizations(recruiterOrganization));
    preferredLink && dispatch(receivePreferredLinks(preferredLink));
    request && dispatch(receiveRequests(request));
  });
});

export const deactivateRequest = (request_id) => (dispatch) => PATCH(
  `/api/v3/employer/requests/${request_id}/deactivate`
).then((res) => {
  const { request } = normalize(JSON.parse(res.data.request));
  dispatch(receiveRequests(request));
});

export const reactivateRequest = (request_id) => (dispatch) => PATCH(
  `/api/v3/employer/requests/${request_id}/reactivate`
).then((res) => {
  const { request } = normalize(JSON.parse(res.data.request));
  dispatch(receiveRequests(request));
});

export const updateDelayedInviteDate = (request_id, delayed_invite_date) => (dispatch) => PATCH(
  `/api/v3/employer/requests/${request_id}/update_delayed_invite_date`,
  { delayed_invite_date }
).then((res) => {
  const { request } = normalize(JSON.parse(res.data.request));
  dispatch(receiveRequests(request));
});

export const deactivateCommunity = (jobCast) => (dispatch) => PATCH(
  `/api/v3/employer/jobcasts/${jobCast.id}/deactivate_community_requests`
).then((res) => {
  const { jobcast } = normalize(JSON.parse(res.data.jobcast));
  dispatch(receiveJobcasts(jobcast));
});

export const reactivateCommunity = (jobCast) => (dispatch) => PATCH(
  `/api/v3/employer/jobcasts/${jobCast.id}/reactivate_community_requests`
).then((res) => {
  const { jobcast } = normalize(JSON.parse(res.data.jobcast));
  dispatch(receiveJobcasts(jobcast));
});

export const updateCommunityDelayedInviteDate = (request_id, delayed_invite_date) => (dispatch) =>
  PATCH(
    `/api/v3/employer/jobcasts/${request_id}/update_community_delayed_invite_date`,
    { delayed_invite_date }
  ).then((res) => {
    const { jobcast } = normalize(JSON.parse(res.data.jobcast));
    dispatch(receiveJobcasts(jobcast));
  });
