export const FETCH_CONTRACT_SUCCESS = "FETCH_CONTRACT_SUCCESS";
export const FETCH_CONTRACTS_ERROR = "FETCH_CONTRACT_ERROR";
export const RECEIVE_CONTRACTS = "RECEIVE_CONTRACTS";
export const CLEAR_CONTRACTS = "CLEAR_CONTRACTS";

export const fetchContractsError = (error) => ({
  type: FETCH_CONTRACTS_ERROR,
  payload: error,
});

export const fetchContractSuccess = (contract) => ({
  type: FETCH_CONTRACT_SUCCESS,
  payload: contract,
});

export const receiveContracts = (contracts) => ({
  type: RECEIVE_CONTRACTS,
  payload: contracts,
});

export const clearContracts = () => ({
  type: CLEAR_CONTRACTS,
});
