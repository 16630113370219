import normalize from "json-api-normalizer";
import forEach from "lodash/forEach";

import queryString from "query-string";
import { batch } from "react-redux";

import { GET, POST, PATCH } from "../../util/apiHelpers";
import { mapKeysToCamelCase, mapKeysToSnakeCase } from "../../util/formatHelpers";
import {
  receiveCandidate,
  receiveCandidates,
} from "../shared/candidateActions";
import {
  fetchContractSuccess,
  receiveContracts,
  fetchContractsError,
} from "../shared/contractActions";
import { receiveJobcasts, receiveJobCast } from "../shared/jobcastActions";
import {
  receivePayPeriods,
  fetchPayPeriodSuccess,
} from "../shared/payPeriodActions";
import { refetchLeftDrawerCounts } from "../shared/uiActions";

export function fetchContracts(filters = {}) {
  let url = "/api/v3/employer/contracts";

  const queryStr = queryString.stringify(mapKeysToSnakeCase(filters));
  url += queryStr ? `?${queryStr}` : "";

  return (dispatch) => {
    return GET(url)
      .then((response) => {
        const normalized = normalize(response.data);

        batch(() => {
          forEach(normalized.candidate, (candidate) => {
            dispatch(receiveCandidate(candidate));
          });
          dispatch(receiveContracts(normalized.contract));
          dispatch(receiveJobcasts(normalized.jobcast));
          dispatch(receivePayPeriods(normalized.payPeriod));
        });

        return { items: normalized.contract, metaData: mapKeysToCamelCase(response.data.meta) };
      })
      .catch((error) => {
        return dispatch(fetchContractsError(error));
      });
  };
}

export const createContract = (data) => (dispatch) =>
  POST("/api/v3/employer/contracts", data)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(
          receiveCandidate(normalized.candidate[data.recruiter_submission_id])
        );
        dispatch(refetchLeftDrawerCounts());
      });
    })
    .catch((errors) => Promise.reject(errors.response.data));

export const completePreassignment = (data) => (dispatch) => {
  return PATCH(
    `/api/v3/employer/contracts/${data.contract_id}/complete_preassignment`,
    data
  ).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(fetchContractSuccess(normalized.contract));
      dispatch(receiveCandidates(normalized.candidate));
      dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
      dispatch(refetchLeftDrawerCounts());
    });
  });
};

export const fetchContract = (candidateId) => (dispatch) =>
  GET(`/api/v3/employer/candidates/${candidateId}/contract`)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveCandidate(normalized.candidate[candidateId]));
        dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
      });
    })
    .catch((errors) => Promise.reject(errors.response.data));

export const closeContract = (candidateId, data) => (dispatch) =>
  PATCH(`/api/v3/employer/candidates/${candidateId}/contract/close`, data)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveCandidate(normalized.candidate[candidateId]));
        dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
        dispatch(receiveJobCast(normalized.jobcast));
        dispatch(refetchLeftDrawerCounts());
      });
    })
    .catch((errors) => Promise.reject(errors));

export const editContract = (candidateId, contractId, data) => (dispatch) =>
  PATCH(
    `/api/v3/employer/candidates/${candidateId}/contracts/${contractId}`,
    data
  )
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveCandidate(normalized.candidate[candidateId]));
        dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
        dispatch(refetchLeftDrawerCounts());
      });
    })
    .catch((errors) => Promise.reject(errors));

export const createOffPlatformContract = (data) => (dispatch) =>
  POST("/api/v3/employer/contracts", data).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(fetchContractSuccess(normalized.contract));
      dispatch(receiveCandidates(normalized.candidate));
      dispatch(receiveJobcasts(normalized.jobcast));
      dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
      dispatch(refetchLeftDrawerCounts());
    });
  });

export const convertContract = (candidateId, data) => (dispatch) =>
  PATCH(`/api/v3/employer/candidates/${candidateId}/contract/convert`, data)
    .then((res) => {
      const normalized = normalize(res.data);

      return batch(() => {
        dispatch(fetchContractSuccess(normalized.contract));
        dispatch(receiveJobcasts(normalized.jobcast));
        dispatch(receiveCandidate(normalized.candidate[candidateId]));
        dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
        dispatch(refetchLeftDrawerCounts());
      });
    })
    .catch((errors) => Promise.reject(errors));
