import merge from "lodash/merge";

import {
  FETCH_CONTRACTS_ERROR,
  RECEIVE_CONTRACTS,
  FETCH_CONTRACT_SUCCESS,
  CLEAR_CONTRACTS,
} from "../actions/shared/contractActions";

const initialState = {
  loaded: false,
  items: {},
  error: null,
};

export default function contractReducer(
  state = initialState,
  { type, payload } = {}
) {
  switch (type) {
    case FETCH_CONTRACTS_ERROR:
      return {
        ...state,
        loaded: true,
        error: payload,
      };
    case RECEIVE_CONTRACTS:
      return merge({}, state, {
        items: payload,
        loaded: true,
        error: null,
      });
    case FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        loaded: true,
        items: {
          ...state.items,
          ...payload,
        },
      };
    case CLEAR_CONTRACTS:
      return initialState;
    default:
      return state;
  }
}
